import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import './Landscape.css'

const Landscape = () => {
    return (
        <>
        <Header/>
        <div className='Landscape'>

        <div className='card'>
            <img src="https://format.creatorcdn.com/5208a3a8-6fca-45b3-bf36-ccaa17ae23da/0/0/0/0,0,2000,1125,1600,900/0-0-0/0a73ab2b-a182-4d1e-967d-f2a496bbb85d/1/2/P1060251-web.jpg?fjkss=exp=1992863388~hmac=f845d738bb43e72575d96093aa64c78f03fc9c8b004fba445c76b0963f04fe40&1600" alt="" />
            <p>Opening the circle</p>
        </div>

        <div>
            <div className='card'>
                <img src="https://format.creatorcdn.com/5208a3a8-6fca-45b3-bf36-ccaa17ae23da/0/0/0/0,0,2000,1572,700,550/0-0-0/795db7a2-2b99-4098-94e4-8360bc95254c/1/2/Black_Mamba_adj-2.jpg?fjkss=exp=1992863388~hmac=342c32fbcc2ec7862fcef61d172a9a1aa4de0d960bb0edf07d8b79f6576a9980&700" alt="" />
                <p>Black Mamba</p>
            </div>

            <div className='card'>
                <img src="https://format.creatorcdn.com/5208a3a8-6fca-45b3-bf36-ccaa17ae23da/0/0/0/0,0,797,1000,700,878/0-0-0/46530515-30bb-4f0a-b5d5-deb5953194ae/1/2/Lotus_Line-adj-web.jpg?fjkss=exp=1992863388~hmac=5287b11d373f2b8c6c3702c810fbe38a8af4ea2a7a41f3e858d2155cb2414cd2&700" alt="" />
                <p>Lotus line</p>
            </div>
        </div>

        </div>
        <Footer/>
        </>
    )
}

export default Landscape