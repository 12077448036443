// import React, { useState } from 'react'
import './Header.css'
import { Link } from 'react-router-dom'

import React, { useState, useRef, useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import { FiMenu } from 'react-icons/fi';


const Header = () => {
    const [navbarOpen, setNavbarOpen] = useState(false);

    const ref = useRef();
    useEffect(() => {
        const handler = (event) => {
            if (
            navbarOpen &&
            ref.current &&
            !ref.current.contains(event.target)
            ) {
            setNavbarOpen(false);
            }
        };
        document.addEventListener('mousedown', handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', handler);
        };
    }, [navbarOpen]);



    return (
        <div className='Header'>
            <header>
                <p>DAN MULAJ</p>
            </header>

            <nav ref={ref} className="navbar">
                <button
                className="toggle"
                onClick={() => setNavbarOpen((prev) => !prev)}
                >
                {navbarOpen ?  (
                                <MdClose style={{ width: '32px', height: '32px' }} />
                                ) : (
                                    <FiMenu
                                    style={{
                                        width: '32px',
                                        height: '32px',
                                    }}
                                    />
                                )}
                </button>
                <ul className={`menu-nav${navbarOpen ? ' show-menu' : ''}`}>
                    <li><Link to='/'>HOME</Link></li>
                    <li><Link to='/sculpture'>SCULPTURE</Link></li>
                    <li><Link to='/landscape'>LANDSCAPE</Link></li>
                    <li><Link to='/about'>ABOUT</Link></li>
                    <li><Link to='/contact'>CONTACT</Link></li>
                </ul>
            </nav>    

        </div>

    )
}

export default Header