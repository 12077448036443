import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import './Home.css'

const Home = () => {
    return (
        <>
        <Header />
            <div className='Home'>
                <img src="https://format.creatorcdn.com/5208a3a8-6fca-45b3-bf36-ccaa17ae23da/0/0/0/0,0,1311,1084,2500,1084/0-0-0/16509493-e3a3-4456-a8a4-b55c5596df51/1/1/EM_Head_detail_crop_adj_web.jpg?fjkss=exp=1992863428~hmac=6f4edad8165c781b730483958d66facbfef0e7c7e9ffee4072daa97fe3f6ad69&1311" alt="" />
            </div>
        <Footer/>
        </>
    )
}

export default Home