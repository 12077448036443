import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import './Contact.css'

const Contact = () => {
    return (
        <>
            <Header/>
            <div className="Contact">
                <div>
                    <p>Phone</p>
                    <a href="#">(+44)123123123</a>
                </div>
                <div>
                    <p>Email</p>
                    <a href="#">test@gmail.com</a>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Contact