import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import './About.css'

const About = () => {
  return (
    <>
    <Header/>
        <div className='About'>
            <div className="about__left">
                <img src="https://images.pexels.com/photos/4194850/pexels-photo-4194850.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="" />
            </div>
            <div className="about__right">
                <p>
                Jonathan Froud has a distinguished exhibiting history, including pieces shown at Tate Britain and The Serpentine gallery, and a one man show at The Walker gallery in Liverpool. Among his awards are The Henry Moore foundation, Royal College of Art major travel scholarship, British Council, Greater London Arts Association Bursary, and more recently the Sculpture prize at the Royal Academy Summer Show. He has undertaken many private commissions including Earthman for Anita Roddick. His work is in private collections in Europe, United States, Japan and South Africa.
                </p>

                <p>
                Froud’s early works explored matter and elements of nature, an oyster shell, a Fibonacci sequence; forms structured by water. Initially his interest was the fluid quality of glass, using it as a metaphor for water, then he realised water had the capability of altering the glass, chemically changing it and bonding surfaces together. Water was beginning a dialogue with the glass, one matter against another, the pieces became about conversation.
                </p>

                <p>
                Water has become central to Froud's work. He has become more interested in the science of matter - in particular the qualities of water, and its properties, which include carrying and holding information, as well as how water is environmentally restructured, reorganised and reformed.
                </p>

                <p>
                'We are mostly water and we are sensitive to vibrations of water, so it’s possible our emotional state creates and/or changes the vibrational structure of water. Our engagement with matter has to happen on a multidimensional level. My interest is in changing people’s awareness of how everything is interlinked, water being an agent for this. We are now collectively realising our responsibility to care of this element.’
                </p>

                <p>
                As well as working in his studio, he is collaborating with landscape architects on large water projects, involving natural filtration systems to maintain water's integrity. Through setting pieces into larger environments he continues to explore how water moves and transforms throughout its life.
                </p>
            </div>
        </div>
    <Footer/>
    </>
  )
}

export default About