import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import './Sculpture.css'

const Sculpture = () => {
    return (
        <>
        <Header/>
        <div className='Sculpture'>


            <div className='card'>
                <img src="https://format.creatorcdn.com/5208a3a8-6fca-45b3-bf36-ccaa17ae23da/0/0/0/399,92,1720,1016,1600,1119/0-0-0/3ff5da1d-ecaa-4b92-82dd-283d749f5928/1/2/EM_Head_detail_adj_web.jpg?fjkss=exp=1992894664~hmac=8c4427e0235acc61eebebadff20ef835f16e6bf9415495ebaa35e813c679f031&1600" alt="" />
                <p>Earthman</p>
            </div>

            <div className="sculpture__2">
                <div className="sculpture__2__left">
                    <div className='card'>
                        <img src="https://format.creatorcdn.com/5208a3a8-6fca-45b3-bf36-ccaa17ae23da/0/0/0/0,0,2000,1315,700,460/0-0-0/18da59a9-8524-4049-89f0-3122910e4e55/1/2/Tate_Wave-web-adj.jpg?fjkss=exp=1992894664~hmac=46cb88ad8c13ce513e96af50d4da4d99de00292ed582fc73a5ac1f7b2247118a&700" alt="" />
                        <p>Tate clover</p>
                    </div>

                    <div className='card'>
                        <img src="https://format.creatorcdn.com/5208a3a8-6fca-45b3-bf36-ccaa17ae23da/0/0/0/0,0,2000,1401,700,490/0-0-0/94859d35-3369-4d86-9af2-47aaa03b20fa/1/2/Pyramids_web.jpg?fjkss=exp=1992894664~hmac=95390c296cf76b3f49fa909eb2692ed4fe236ea62a954ceca18971da42096b32&700" alt="" />
                        <p>Pyramids</p>
                    </div>
                </div>

                <div className="sculpture__2__right">
                    <div className='card'>
                        <img src="https://format.creatorcdn.com/5208a3a8-6fca-45b3-bf36-ccaa17ae23da/0/0/0/0,0,1177,1772,700,1054/0-0-0/b62e5e2d-383e-4a5d-b885-3734784569a2/1/2/Blow_Me_Henry_adjust.jpg?fjkss=exp=1992894664~hmac=6f9cb69d570b256646c769ebf58ee940af2b1f2b38a8ddec4b07cae692fe9597&700" alt="" />
                        <p>Abraided</p>
                    </div>
                </div>
            </div>


            <div className="sculpture__3">
                <div className="sculpture__3__left">
                    <div className='card'>
                        <img src="https://format.creatorcdn.com/5208a3a8-6fca-45b3-bf36-ccaa17ae23da/0/0/0/0,0,2000,1339,700,469/0-0-0/4f108748-326f-463c-a431-c88a67af4449/1/2/Oyster-web-adj.jpg?fjkss=exp=1992894665~hmac=b485a02b6b8bc374fdafae5d2242469355f828e5d711adeadcc71d0442025e6e&700" alt="" />
                        <p>Oyster</p>
                    </div>

                    <div className='card'>
                        <img src="https://format.creatorcdn.com/5208a3a8-6fca-45b3-bf36-ccaa17ae23da/0/0/0/0,0,673,1000,700,1040/0-0-0/5da74b3d-aa76-4220-8724-dbafc72bb25b/1/2/Tanis_Synx-apex-web-adj.jpg?fjkss=exp=1992894665~hmac=d41b755343a0321fba507690f6b3cf47e25f3c6a7d8863f8007e7c412aea154e&700" alt="" />
                        <p>Tanis & Synx</p>
                    </div>
                </div>

                <div className="sculpture__3__right">
                    <div className='card'>
                        <img src="https://format.creatorcdn.com/5208a3a8-6fca-45b3-bf36-ccaa17ae23da/0/0/0/0,0,689,1000,700,1016/0-0-0/de002fcd-919e-4566-9bbf-9a0e3cbabe00/1/2/Listening_Learning-adj-web.jpg?fjkss=exp=1992894665~hmac=2f7b9072221745e70e9645eb3ff2090055e77c65e8589b10b152efb5dda8186e&700" alt="" />
                        <p>Listening Learning</p>
                    </div>
                    
                    <div className='card'>
                        <img src="https://format.creatorcdn.com/5208a3a8-6fca-45b3-bf36-ccaa17ae23da/0/0/0/0,0,1988,1328,700,468/0-0-0/a478dda3-d03a-4efa-8466-b993f437e6b2/1/2/When_the_eye_is_in_web.jpg?fjkss=exp=1992894665~hmac=5a67c1ba247ac6d7186aca2f0917700693fb329c452d1a12daafc1efa581fcc7&700" alt="" />
                        <p>When the eye is in</p>
                    </div>
                </div>
            </div>



            <div className='card'>
                <img src="https://format.creatorcdn.com/5208a3a8-6fca-45b3-bf36-ccaa17ae23da/0/0/0/660,106,1988,884,1600,937/0-0-0/ef4a10c9-597c-4e7a-a718-a1ef81258787/1/2/Colossus-adj-web.jpg?fjkss=exp=1992894665~hmac=ddfd71c6fec31d6513e71ab79aff1dbc954ae739e533823567008ba023228991&1600" alt="" />
                <p>Colossus</p>
            </div>

        </div>
        <Footer/>
        </>
    )
}

export default Sculpture